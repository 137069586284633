*{
  text-align: center;
  font-family: "Stylish", serif;
  font-weight: 800;
  font-style: normal;
}


.image-right-corner {
  position: absolute;
  top: 150px;
  right: 300px;
}

@media (max-width: 768px) {
  .image-right-corner {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.AboutMe{
  display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: justify;
   gap: 5px;
   padding: 20px;
   margin-left: -30%;  
   top : 500px;
}

.AboutMe-icons{
  display:flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap:20px;

}

.AboutMe-icons i {
  font-size: 35px;
  display:flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  
}

.AboutMe-button{
  margin-Right: 20px;
  color :  lightskyblue; 
}





  




/* .text-corner {
    top: 300px;
    right: 80px;
    height: 50px;
    column-gap:0.5rem;
    color: lightskyblue; 
}

.text-corner i{
    font-size: 24px;
    color: lightskyblue; 
}

.icon-corner {
  position: absolute;
  top: 500px;
  right: 80px;
  display:flex;
  column-gap:0.5rem;
}

.left-corner {
  position: absolute;
  top: 190px;
  left: 20px;
}

.Rintu{
  display:flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 50px;
  margin-bottom: 50px;
} */






