
.footerStyle{
    background-color: #000;
    padding: 3rem;
    text-align: center;
    color:#fff;
    margin-bottom: 2rem;
}

.footer-title{
    margin-bottom: 2rem;
    color: lightskyblue; 
}

.footer-skill{
    display: flex;
    grid-template-columns: 1fr max-content 1fr;
    justify-content: center;
    column-gap: 1.5rem;
    font-style: italic;
    margin-bottom: 2rem;
    color: lightskyblue; 
}

.footer-icons{
    display: flex;
    grid-template-columns: 1fr max-content 1fr;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: 2rem;

}

.footer-icons i {
    font-size: 33px;
}

.footer-rights{
 
  display: flex;
  column-gap: 1.5rem;
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Center items vertically */
  color: #fff;
}