.quali-container{
    max-width: 900px;
}

.quali-section{
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

.quali-tabs{
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
  
    
}

.quali-button{
  font-size: 1.2rem; 
  font-weight: 400; 
  color: var(--title-color); 
  margin: 0 0.5rem; 
  cursor: pointer;
}

.quali-button:hover{
    color: var(--title-color-dark);
}

.quali-active{
    color: var(--title-color-dark);
}

.quali-content{
    display:none;
}

.quali-content-active{
    display:block;
}

.quali-data{
    display:grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.quali-title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
 
}

.quali-subtitle{
    display:inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
    font-style: italic;
    color :  rgb(77, 161, 213); 
}

.quali-calender{
    font-size: var(--small-font-size);
}

.quali-rounder{
    display:inline-block;
    width:13px;
    height:13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.quali-line{
    display: block;
    width:1px;
    height:100%;
    background-color: var(--text-color);
    transform: translate(6px,-7px);
}

.section-title{
    margin-left: 140px;  
}

.small-button {
    font-size: 50%;
    background-color: #000;
  color: #fff;
    padding: 0.25rem 0.5rem;
  }
  

  