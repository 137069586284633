

.skill-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* top:-100px; */
    /* width: 80vw;
    height: 80vh; */
    position:relative;
    /* margin-left: 140px;   */
  }
  
  .skill-box {
    display: flex;  
    justify-content: center;
    align-items: center;
    gap:25px;
    margin-top: 20px;
    margin-bottom: 20px;
    /* margin: 10px; Add some margin between the boxes */
  }
  
  .skill-form {
    width: 420px;
    box-shadow:  rgb(0 0 0 / 16%) 1px 1px 10px; 
    justify-content:center;
    position:relative;
    align-items: center; 
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    background-color:  rgb(221, 232, 238);;
    /* background-color: white; */
  }
  
  .skill-content {
    /* display: flex; */
    /* flex-direction: row; */
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .skill-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 500;
  }
  
  .skill-split{
    display:flex;
    /* justify-content:center; */
    column-gap:5rem;
    
  }

  .skill-group {
    display:grid;
    align-items:flex-start;
    row-gap:1rem;

  }

  .skill-data{
    display:flex;
    column-gap:0.5rem;
  }
  

  .skill-name{
    font-size:var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height:18px;
  }
  .skill-level{
    font-size: var(--tiny-font-size);
  }

  .skill-data i {
    font-size: 22px; /* Adjust the icon size as needed */
  }