
  .Auth-form-container {
    position:relative; 
    top:-10px;  
    display: flex;
    justify-content:center;
    align-items: center; 
    /* width: 80vw;
    height: 80vh; */
  }
  
  
  .Auth-form {
    width: 650px;
    box-shadow:  rgb(0 0 0 / 16%) 1px 1px 10px; 
    justify-content:center;
    position:relative;
    align-items: center; 
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    background-color: white;
    margin-left: -220px;
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 34px;
    color: #5da9c7;
    font-weight: 800;
    font-style: italic; 
    text-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  }

  .title{
    text-align: center;
    /* margin-bottom: 1em; */
    font-size: 34px;
    color:#5da9c7;
    font-weight: 500;
    font-style: italic; 
    text-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;

  }
  .skill-title{
    text-align: center;
    /* margin-bottom: 1em; */
    font-size: 34px;
    color:#5da9c7;
    font-weight: 500;
    font-style: italic; 
    text-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    
  }
  
  .super{
    text-align: center;
    

  }
  
.contact_icons{
  display: flex;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
  margin-bottom: 2rem;

}

.contact_icons i {
  font-size: 24px;
  /* color: lightskyblue;  */
}
  
.header-text-left{
  color: lightskyblue; 
  font-size: 2.0rem; 
  font-weight: 500;
  font-style: italic; 
}

.header-text{
  color: lightskyblue; 
  font-size: 1.2rem; 
}

.custom-list {
  list-style-type: none; 
  padding-left: 20px; 
  text-align: left; 
  margin: 0; 
  font-weight: lighter;
}

.custom-list li {
  position: relative; /* Position relative to place custom bullets */
  margin-bottom: 10px; /* Add space between list items */
  padding-left: 20px; /* Add padding to make room for bullets */
  text-align: left; /* Ensure text is left-aligned */
}

.custom-list li::before {
  content: "*"; /* Custom bullet */
  color: black; /* Bullet color */
  font-weight: bold; /* Bullet weight */
  position: absolute; /* Position absolute to place bullet correctly */
  left: 0; /* Align bullet to the left */
  top: 0; /* Align bullet to the top */
}

