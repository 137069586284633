.project_container{
    width:750px;
}

#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

/* body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 40%;
  height: 50%;
  box-shadow:  rgb(0 0 0 / 16%) 1px 1px 10px; 
  justify-content:center;
  border-radius: 15px;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 150%;
  height: 150%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

#Pro{
  border: 1px solid black;
  border-radius: 20px;
}
#Pro1{
  display: flex;
  flex-direction: row;
  gap:10px;
  justify-content: center;
  align-items: center;
}



