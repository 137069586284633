
.message-container{
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.message-grid{
    width: 50%;
    height: 50%;
    /* box-shadow:  rgb(0 0 0 / 16%) 1px 1px 10px;   */
    border-radius: 15px;
    padding-bottom: 6.25rem;
}
    
.message-title{
    font-style: italic;
    margin-bottom: 2rem;
    
}
.contact-title{
    font-style: italic; 
    text-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    font-weight: 500; 
    color:#5da9c7;
}


.message-group{
    display:grid;
    grid-template-columns: repeat(2,1fr); 
    column-gap: 1.5rem;
}

.message-div{
    position: relative;
    margin-bottom: 1.875rem;
    height:3.75rem;
}

.message-input{
    position:absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    box-shadow:  rgb(0 0 0 / 16%) 1px 1px 10px; 
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 0.625rem 1.875rem;
    z-index: 1;
}
.button{
    font-size: 1.2rem; 
    font-weight: 500; 
    color: lightskyblue; 
    margin: 0 0.5rem; 
    cursor: pointer;
  }
  
  